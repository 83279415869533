
import { MgcgestionTokenService } from "../mgcgestionTokenService"

const USER_ID='user_id'
const USER_CATEGORY='user_category'
const CLICK_NAVIGATION='click.navigation'
const CLICK_ACTION='click.action'
const CLICK_EXIT='click.exit'
const CLICK_DOWNLOAD='click.download'
const SEARCH_DISPLAY='internal_search_result.display'
const SEARCH_CLICK='internal_search_result.click'

export class GTMService {

    static sendClick(content) {
        console.log(content);
        window.dataLayer.push(content);
        console.log("sendClick");

    }

  }

