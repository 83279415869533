import Axios from "axios"
import React from "react"
import Layout from "../components/layout"
import { Tabs, Tab, Spinner } from "react-bootstrap"
import IconMail from "../images/icon-mail.png"
import IconLocalSection from "../images/icon-local-section.png"
import LegalNoticeContact from "../components/legalNoticeContact"
import "./contact.css"
import { ATInternetTagService } from './../services/global/ATInternetService';
import { GTMService } from './../services/global/GTMService';
import horairesAffluence from "../images/horaires-affluence.png"

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      civility: "",
      email: "",
      firstName: "",
      lastName: "",
      member: "",
      message: "",
      question: "Demande info",
      sentSuccess: false,
      sentError: false,
      sending: false,
      disabledsubmit : false
    }
    this.handleChangeCivility = this.handleChangeCivility.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangeFirstName = this.handleChangeFirstName.bind(this)
    this.handleChangeLastName = this.handleChangeLastName.bind(this)
    this.handleChangeMessage = this.handleChangeMessage.bind(this)
    this.handleChangeQuestion = this.handleChangeQuestion.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submitButton = React.createRef()
  }
  componentDidMount() {
    ATInternetTagService.sendPage({page:'nous_contacter',page_chapter1:'contact'});
  }

  render() {
    const {
      sending,
      sentSuccess,
      sentError,
      civility,
      lastName,
      firstName,
      email,
      question,
      message,
      disabledsubmit
    } = this.state
    return (
      <Layout
        titleSeo="Nous contacter"
        keywordsSeo={[`contact`]}
        description={
          "Vous avez une question, un doute ou besoin d'un conseil ? Contactez-nous par email, téléphone, courrier ou rendez-vous dans l'une de nos agences."
        }
      >
        <section className="pageHeader">
          <div className="pageHeaderContainer">
            <nav>
              <ul className="breadcrumb">
                <li className="breadcrumbItem">
                  <a
                    href="/"
                    className="breadcrumbItemLink breadcrumbItemLinkHome"
                  >
                    <i className="icon-mgc" />
                  </a>
                </li>
                <li className="breadcrumbItem" key="/contact">
                  <a
                    className="breadcrumbItemLink breadcrumbItemLinkMiddle"
                    href="/contact"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>
        <section className="pageHeader callback">
          <div>
            <h1 className="pageHeaderTitle">Nous Contacter</h1>
          </div>
        </section>
        <section className="contact">
          <Tabs
            defaultActiveKey="no-adherent"
            id="uncontrolled-tab-example"
            className="nav nav-tabs"
            onSelect={(key) => ATInternetTagService.sendNavClick({click:(key === 'adherent'?'je suis adherent':'je ne suis pas adherent')})}
            >
            <Tab
              eventKey="no-adherent"
              title="Je ne suis pas adhérent"
              className="nav-item contactBody"
              >
              <div className="contactNonAdherent">
                <div className="contactBodySocial">
                  <a href="https://www.facebook.com/mutuelle.mgc" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'facebook',click_chapter2:'Reseaux_sociaux'})}>
                    <i className="icon-facebook-circle" />
                  </a>
                  <a href="https://twitter.com/groupeMGC" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'twitter',click_chapter2:'Reseaux_sociaux'})}>
                    <i className="icon-twitter-circle" />
                  </a>
                  <a href="https://www.linkedin.com/company/groupe-mgc" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'linkedin',click_chapter2:'Reseaux_sociaux'})}>
                    <i className="icon-linkedin-circle" />
                  </a>
                  <a href="https://www.youtube.com/user/MGCFILMS" target="_blank" onClick={() => ATInternetTagService.sendExitClick({click:'youtube',click_chapter2:'Reseaux_sociaux'})}>
                    <i className="icon-youtube-circle" />
                  </a>
                </div>
                <div className="contactBodyContent">
                  <div className="contactBodyContentLinks">
                    <div>
                      <h3 className="contactBodyContentTitle">
                        <i className="icon-phone" />
                        TELEPHONE
                      </h3>
                      <p>
                        <a href="tel:01 40 78 57 10" target="_blank" onClick={() => GTMService.sendClick({event: "contact_tel"})}>01 40 78 57 10</a> (je ne
                        suis pas adhérent)
                      </p>
                      <p className="contactBodyContentSmall">
                        du lundi au jeudi de 9h00 à 18h00 et le vendredi de 9h00 à 17h30.
                      </p>
                    </div>
                    <div>
                      <h3 className="contactBodyContentTitle">
                        <i className="icon-email" />
                        COURRIER
                      </h3>
                      <p>
                        Mutuelle MGC
                        <br />
                        Service devis et adhésions
                        <br />2 et 4 place de l’abbé Georges Hénocque
                        <br />
                        75621 Paris Cedex 13
                      </p>
                    </div>
                    <div>
                      <h3 className="contactBodyContentTitle">
                        <i className="icon-location" />
                        Agences
                      </h3>
                      <a href="/agences/">Localiser une agence MGC</a>
                    </div>
                  </div>
                  <div className="contactBodyContentForm">
                    <h3 className="contactBodyContentTitle">
                      <img
                        src={IconMail}
                        alt="E-mail"
                        className="contactBodyContentTitleImage"
                      />
                      E-mail
                    </h3>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group row civilite-contact">
                        <label
                          htmlFor="input_civility"
                          className="col-3 col-form-label contactBodyContentFormLabel"
                        >
                          Civilité
                        </label>
                        <div className="col-9">
                          <ul
                            className="contactBodyContentFormCustomRadio"
                            id="input_civility"
                          >
                            <li>
                              <input
                                type="radio"
                                name="radio"
                                id="radio01"
                                value="MADAME"
                                checked={civility === "MADAME"}
                                onChange={this.handleChangeCivility}
                                required
                              />
                              <label htmlFor="radio01">Madame</label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                name="radio"
                                id="radio02"
                                value="MONSIEUR"
                                checked={civility === "MONSIEUR"}
                                onChange={this.handleChangeCivility}
                                required
                              />
                              <label htmlFor="radio02">Monsieur</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input_last_name"
                          className="col-3 col-form-label contactBodyContentFormLabel"
                        >
                          NOM
                        </label>
                        <div className="col-9">
                          <input
                            type="text"
                            className="form-control"
                            id="input_last_name"
                            value={lastName}
                            onChange={this.handleChangeLastName}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input_first_name"
                          className="col-3 col-form-label contactBodyContentFormLabel"
                        >
                          Prénom
                        </label>
                        <div className="col-9">
                          <input
                            type="text"
                            className="form-control"
                            id="input_first_name"
                            value={firstName}
                            onChange={this.handleChangeFirstName}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input_email"
                          className="col-3 col-form-label contactBodyContentFormLabel"
                        >
                          Courriel
                        </label>
                        <div className="col-9">
                          <input
                            type="email"
                            className="form-control"
                            id="input_email"
                            value={email}
                            onChange={this.handleChangeEmail}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input_object"
                          className="col-3 col-form-label contactBodyContentFormLabel"
                        >
                          Objet
                        </label>
                        <div className="col-9">
                          <select
                            className="contactBodyContentFormSelect form-control"
                            id="input_object"
                            value={question}
                            onChange={this.handleChangeQuestion}
                            required
                          >
                            <option defaultValue value="">
                              - Objet -
                            </option>
                            <option value="info">
                              Demande d’information sur l’offre MGC
                            </option>
                            <option value="adhesion">
                              Ma demande d'adhésion en cours
                            </option>
                            <option value="devis">Mon devis santé</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input_message"
                          className="col-3 col-form-label contactBodyContentFormLabel"
                        >
                          Message
                        </label>
                        <div className="col-9">
                          <textarea
                            className="form-control"
                            id="input_message"
                            rows="3"
                            value={message}
                            onChange={this.handleChangeMessage}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row" hidden={!sending}>
                        <div className="col offset-3 text-center">
                          <Spinner animation={"border"} role={"status"}>
                            <span className="sr-only">Chargement...</span>
                          </Spinner>
                        </div>
                      </div>

                      {sentSuccess && (
                        <div className="alert alert-success" role="alert">
                          Votre demande a bien été prise en compte.
                        </div>
                      )}
                      {sentError && (
                        <div className="alert alert-danger" role="alert">
                          Une erreur s'est produite
                        </div>
                      )}

                      <div className="form-group row">
                        <label className="col-3 contactBodyContentFormLabelHidden" />
                        <div className="col">
                          <input
                            type="submit"
                            className="contactBodyContentFormButton"
                            value="Envoyer"
                            ref={this.submitButton}
                            disabled={sending || disabledsubmit}
                          />
                        </div>
                      </div>
                      <div className="col offset-2">
                         <div className="contactBodyContentAdherent">
                        <div className="contactBodyContentTitle">EN CAS DE RECLAMATION</div>               
                          <div>Mutuelle MGC – Service réclamation TSA 91347 75 621 Paris Cedex 13</div>
                          
                          <div className="mt-2">
                            <div className="contactBodyContentTitle">
                              EN CAS DE DEMANDE DE MÉDIATION
                            </div>
                            <p>
                            Par courrier : Monsieur le Médiateur de la Mutualité Française
                            FNMF, 255 rue de Vaugirard - 75719 PARIS cedex 15 <br/>
                            Sur le site internet du médiateur <a href="https://www.mediateur-mutualite.fr/" target="_blank"> https://www.mediateur-mutualite.fr/</a>. Voir les modalités de saisine sur ce site.
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              eventKey="adherent"
              title={
                <>
                  Je suis adhérent : <br className="d-sm-none" /> 01 40 78 06 91
                </>
              }
              className="nav-item "
              >
              <div className="contactAdherent">
                <div className="contactBodyContent contactBody">
                  <div className="contactBodyContentLinks">
                    <div>
                      <h4 className="contactBodyContentTitle">
                        <i className="icon-phone" />
                        TELEPHONE
                      </h4>
                      <p>
                        <a href="tel:01 40 78 06 91" target="_blank" onClick={() => GTMService.sendClick({event: "contact_tel"})}>01 40 78 06 91</a>
                      </p>
                      <p className="contactBodyContentSmall">
                        du lundi au vendredi de 8h15 à 18h00
                      </p>
                      <img src={horairesAffluence} className="contactHorairesAffluenceImage" alt="horaires affluence" />
                    </div>
                    <div>
                      <h3 className="contactBodyContentTitle">
                        <i className="icon-email" />
                        COURRIER
                      </h3>
                      <p>
                        Mutuelle MGC
                        <br />
                        Service Adhérent
                        <br />
                        TSA 91347
                        <br />
                        75621 Paris Cedex 13
                      </p>
                      <p>
                        Fax : <a href="#">01 40 78 06 88</a>
                      </p>
                    </div>
                    <div>
                      <h4 className="contactBodyContentTitle">
                        <i className="icon-location" />
                        AGENCES{" "}
                      </h4>
                      <a href="/agences/">Localiser une agence MGC</a>
                    </div>
                    <div>
                      <h3 className="contactBodyContentTitle">
                        <img src={IconLocalSection} alt="Sections locales" />
                        COMITÉS LOCAUX
                      </h3>
                      <a href="/la-mutuelle-mgc/correspondants-locaux/">
                        Découvrir les comités locaux
                      </a>
                    </div>
                  </div>
                  <div className="contactBodyContentAdherent">
                    <h3 className="contactBodyContentTitle">Messagerie</h3>
                    <div>
                      <p>
                        Contactez-nous via&nbsp;
                        <a
                          href="https://monespacepersonnel.cimut.net/web/mgc"
                          target="_blank"
                        >
                          la messagerie de votre espace
                          adhérent sécurisé <br />
                          rubrique :{" "}
                          mes contacts > ma messagerie{" "}
                        </a>
                      </p>
                    </div>
                    <h3 className="contactBodyContentTitle">
                      POUR UNE ASSISTANCE À DOMICILE
                    </h3>
                    <div data-nosnippet="data-nosnippet">
                      <p>
                        MGC Assistance 24h/24 et 7j/7
                        <br />
                        <a href="tel:01 41 77 45 77">
                          01 41 77 45 77 (appel non surtaxé).
                        </a>
                      </p>
                    </div>
                    <div>
                      <h3 className="contactBodyContentTitle">
                        EN CAS DE RÉCLAMATION
                      </h3>
                      <p>
                        {" "}
                        En laissant votre demande sur votre espace adhérent. <br/>
                        Par courrier : Mutuelle MGC – Service réclamation TSA 91347 75 621 Paris Cedex 13
                      </p>
                    </div>
                    <div>
                      <h3 className="contactBodyContentTitle">
                        EN CAS DE DEMANDE DE MÉDIATION
                      </h3>
                      <p>
                        {" "}
                        Par courrier : Monsieur le Médiateur de la Mutualité Française <br />
                        FNMF, 255 rue de Vaugirard - 75719 PARIS cedex 15
                        <br />
                        Sur le site internet du médiateur <a href="https://www.mediateur-mutualite.fr/" target="_blank">https://www.mediateur-mutualite.fr/</a>
                        . Voir les modalités de saisine sur ce site.
                      </p>
                    </div>
                    <div>
                      <h3 className="contactBodyContentTitle">
                        POUR TOUT AUTRE SUJET
                      </h3>
                      <p>
                        {" "}
                        Mutuelle MGC 2 et 4 place de l’Abbé Hénocque 75 013
                        Paris
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          <LegalNoticeContact />
        </section>
      </Layout>
    )
  }

  handleChangeCivility(event) {
    this.setState({ civility: event.target.value })
  }
  handleChangeEmail(event) {
    this.setState({ email: event.target.value })
  }
  handleChangeFirstName(event) {
    this.setState({ firstName: event.target.value })
  }
  handleChangeLastName(event) {
    this.setState({ lastName: event.target.value })
  }
  handleChangeMessage(event) {
    this.setState({ message: event.target.value })
  }
  handleChangeQuestion(event) {
    this.setState({ question: event.target.value })
  }

  handleSubmit(event) {

    console.log(this.state.question);
    var tt = '';
    if(this.state.question==='adhesion'){
      tt = 'Ma demande d’adhésion en cours'
    } else if (this.state.question==='devis'){
      tt = 'Mon devis santé'
    } else {
      tt = 'Demande d’information sur l’offre MGC'
    }

    console.log(tt);

    var idc = this.state.firstName + this.state.lastName;

    window.dataLayer.push({
      event: 'infos',
      user_data: {
        user_id: ATInternetTagService.hashTheID(idc),
        user_first_name: this.state.firstName,
        user_last_name: this.state.lastName,
        user_mail: this.state.email,
        user_phone: ''
      }
    });

    window.dataLayer.push({
      event: "contact_form",
      user_type: "Non Membre",
      contact_subject: tt
    });

    let self = this
    const url = `${process.env.GATSBY_CMS_API_URL}/api/contact`
    self.setState({ sending: true })
    Axios.post(url, {
      civility: this.state.civility,
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      member: true,
      message: this.state.message,
      question: this.state.question,
    })
      .then(function(response) {
        self.setState({ sentSuccess: true, sentError: false, sending: false })
        ATInternetTagService.sendPage({page:'envoyer',page_chapter1:'contact',page_chapter2:'formulaire_nous_contacter',type_objet:self.state.question});
      })
      .catch(function(error) {
        console.error(error)
        self.setState({ sentError: true, sentSuccess: false, sending: false })
      })
    event.preventDefault()

    this.state.civility= ""
    this.state.email= ""
    this.state.firstName= ""
    this.state.lastName= ""
    this.state.member= ""
    this.state.message= ""
    this.state.disabledsubmit = true

    setTimeout(() => {
      this.submitButton.current.disabled = false
    }, 5000);  

  }
}

export default ContactForm
