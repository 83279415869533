import * as React from "react"

const LegalNoticeContact = () => {
  return (
    <div className={"col-10 offset-1 col-lg-12  offset-lg-0 mention-quot-wizard"}>
      <p>
        Les données à caractère personnel (DCP) collectées sont destinées à la
        Mutuelle Générale des Cheminots (MGC) en sa qualité de Responsable de
        traitement et plus précisément aux services habilités de la MGC. Ces DCP
        et informations font l’objet de traitements (automatisé et non
        automatisé). Les données marquées d'une croix rouges sont obligatoires
        et nécessaires au traitement de votre demande ; à défaut la mutuelle MGC
        ne sera pas en mesure de la traiter.
        <br />
        En application de la réglementation française et européenne, vous
        disposez :
        <ul>
          <li>
            d’un droit d’accès, de rectification, d’effacement et de portabilité
            des données vous concernant ;
          </li>
          <li>d’un droit d’opposition et à la limitation du traitement ;</li>
          <li>
            du droit de définir des directives générales, qui peuvent être
            enregistrées auprès d’un tiers de confiance numérique certifié par
            la CNIL ou particulières relatives à la conservation, à l’effacement
            et à la communication de vos DCP après votre décès et qui peuvent
            être enregistrées auprès de la MGC. Pour exercer l’un des droits
            susvisés et en savoir plus sur la politique de protection des
            données de la MGC, nous vous invitons à consulter les conditions
            d’adhésion en ligne et la Charte relative à la protection des
            données figurant sur le site internet de la MGC&nbsp;
            <a href={"https://www.mutuelleMGC.fr"}>www.mutuelleMGC.fr</a>.
          </li>
        </ul>
        Vous pouvez également contacter le Délégué à la Protection des données
        personnelles de la Mutuelle aux coordonnées suivantes&nbsp;:&nbsp;
        <a href={"mailto:service.dpo@m-g-c.com"}>service.dpo@m-g-c.com</a>.
        <br />
        En cas de réclamation sur la gestion de vos données personnelles, vous
        avez également la possibilité de contacter la CNIL.
      </p>
    </div>
  )
}

export default LegalNoticeContact
